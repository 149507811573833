/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import ConfirmationDialog from "src/component/ConfirmationDialog";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { FaWallet, FaSignOutAlt, FaPeopleArrows } from "react-icons/fa";

import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-toastify";

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        // icon: ImHome3,
        href: "/dashboard",
      },
      {
        title: "Node Manager",
        // icon: ImHome3,
        href: "/node-activation",
      },
      // {
      //   title: "API Key",
      //   // icon: ImHome3,
      //   href: "/api-key",
      // },
      {
        title: "My Account",
        // icon: ImHome3,
        href: "/profile",
      },

      {
        title: "Order History",
        // icon: IoMdSwap,
        href: "/order-history",
      },
      // {
      //   title: "Logout",
      //   // icon: FaWallet,
      //   href: "/dashboard",
      // },
    ],
  },
];
const sectionsBelow = [
  {
    items: [],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    // background: "#fff",
    background: "#161618",
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  BetaName: {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Roboto",
    background: "#27272B",
    width: "18%",
    padding: "5px 10px",
    borderRadius: "8px",
    color: "#9E9E9E",
  },
  logoName: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#E2A405",
  },
  desktopDrawer: {
    // width: 255,
    width: 235,
    top: 0,
    height: "100%",
    // background: "#fff",
    background: "#161618",

    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
    // "@media (max-width: 1280px)": {
    //   width: 235,
    // },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: `${theme.palette.text.primary} !important`,
  },

  Terms: {
    color: theme.palette.text.primary,

    // padding: "17px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    "& .MuiButton-label": {
      padding: "10px",
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#fff !important",
        background: "#3A96DD",
        padding: "10px",
        borderRadius: "9px",
        fontWeight: theme.typography.fontWeightRegular,
        "& $title": {
          fontWeight: theme.typography.fontWeightMedium,
          // color: `${theme.palette.text.primary} !important`,
        },
        "& $icon": {
          color: "#fff !important",
          // color: "00e0b0",
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },

  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  buyNodeButton: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    width: "70%",
    color: "#F5C843",
    background: "#000000",
    borderRadius: "12.4194px",
  },
  logOut: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    // fontWeight: "400",
    fontSize: "18px",
    lineHeight: "30px",
    textAlign: "center",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const auth = useContext(AuthContext);
  const [logOutOpen, setLogoutOpen] = useState(false);

  const handleLogoutPopup = () => {
    setLogoutOpen(!logOutOpen);
  };
  const logOut = () => { 
    localStorage.clear();
    setLogoutOpen(!logOutOpen);
    // history.push("/");
    toast.success("Logout succesfully.");
    window.location.reload();
  };
  const confirmationHandler = () => {
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("address");
  };

  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location?.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="srollBoxNav"
      style={{ overflowY: "auto" }}
    >
      <Hidden mdDown>
        <Typography style={{ padding: "10px" }}>
          <div className={classes.BetaName}>BETA</div>
        </Typography>
        <Box mb={3} align="center" style={{marginTop: "-24px", marginBottom: "6px"}}>
          <img
            onClick={() => history.push("/")}
            style={{ cursor: "pointer",width:"100%",maxWidth:"161px" }}
            src="images/MOM.png"
            alt="dashboard layout logo"
          />
          <Typography className={classes.logoName}></Typography>
        </Box>
      </Hidden>
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location?.pathname,
              })}
            </List>
          ))}
          <Box style={{ height: "100px !important" }}>
            {/* <ChartList height="38%" /> */}
          </Box>
        </Box>

        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <Button
                    fullWidth
                    key={i}
                    className={classes.Terms}
                    style={{
                      justifyContent: "start",
                      marginTop: "-23px",
                      // paddingLeft: 36,
                      borderRadius: 0,
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                    // onClick={() => history.push(itemList.href)}
                    onClick={() => {
                      itemList.title === "Logout"
                        ? setOpen(true)
                        : history.push(itemList.href);
                    }}
                  >
                    <FaSignOutAlt
                      style={{ fontSize: "15px" }}
                      className={classes.icon}
                    />
                    {itemList.title}
                  </Button>
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
      <Box align="center">
        <Button
          className={classes.logOut}
          onClick={() => {
            handleLogoutPopup();
          }}
        >
          Log out
        </Button>
      </Box>
      <Box mt={20} align="center">
        <Button
          className={classes.buyNodeButton}
          onClick={() => {
            history.push("/buy-node");
          }}
        >
          Buy Nodes
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <ConfirmationDialog
        open={logOutOpen}
        // title={"Log Out"}
        desc={"Are you sure you want to logout?"}
        handleClose={handleLogoutPopup}
        confirmationHandler={logOut}
      />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
