import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  TextField,
  Button,
  FormHelperText,
  FormControl,
  Tooltip,
  Link,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yep from "yup";
import Scroll from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import Logo from "src/component/Logo";
import { AiOutlineMail } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { FiFacebook, FiTwitter } from "react-icons/fi";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import { FaDiscord, FaFacebookF, FaBitcoin } from "react-icons/fa";
import { AiOutlineGooglePlus } from "react-icons/ai";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
// import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import SettingsContext from "src/context/SettingsContext";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RedditIcon from "@material-ui/icons/Reddit";
import { FiInstagram } from "react-icons/fi";
import { AiOutlineTwitter } from "react-icons/ai";
import { BsMedium } from "react-icons/bs";
import { IoLogoSlack } from "react-icons/io";
import { EmailOutlined } from "@material-ui/icons";

const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  colorFFF: {
    color: "#fff",
  },
  footerSection: {
    // borderTop: "1px solid #D8D8D8",
    // backgroundColor: "#161618",
    backgroundColor: "black",
    // background: "#F3F5F6",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      // paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      // paddingTop: theme.spacing(4),
    },
    "& h1": {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: "31px",
      [theme.breakpoints.down("md")]: {
        fontSize: "33px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "23px",
      },
    },
    "& h6": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "14px",

      color: "#B7B7B7",
    },
    "& h3": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "18px",
      lineHeight: "28px",
      color: "#ffffff",
      [theme.breakpoints.down("xs")]: {
        fontSize: "17px",
      },
    },
    "& h4": {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: "18px",
      color: theme.palette.text.primary,
      // color: "#2C4569",
      marginBottom: "15px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "17px",
      },
    },
    "& .subpart": {
      borderBottom: "",

      // padding: "60px 100px 24px 100px",
      // padding: "50px 100px 24px 100px",
      padding: "50px 130px 24px 130px",
      "& h6": {
        fontFamily: "Roboto",
        fontSize: "13px",
        color: "#848484",
        fontWeight: "400",
        lineHeight: "19px",
        width: "100%",
        maxWidth: "365px",
        "@media(max-width:730px)": {
          fontSize: "13px",
          maxWidth: "264px",
        },
        "@media(max-width:599px)": {
          fontSize: "13px",
          width: "100%",
          maxWidth: "100%",
          marginBottom: "10px",
        },
      },
      "@media(max-width:1280px)": {
        padding: "50px 128px 24px 128px",
      },
      "@media(max-width:600px)": {
        padding: "20px 30px 24px 30px",
      },
      
    },
    "& p": {
      // fontSize: "14px",
      fontWeight: "400",
      padding: "14px 0",
      "@media(max-width:1280px)": {
        padding: "0px",
      },
      "@media(max-width:600px)": {
        padding: "0px",
      },
    },
    "@media (max-width: 500px)": {
      backgroundColor: "#171717",
    },

  },
  baseSection: {
    "& p": {
      fontFamily: "Roboto",
      // marginLeft: "115px",
      textAlign: "right",
      paddingRight: "18px",
      color: "rgba(255, 255, 255, 0.6)",
      fontSize: "18px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    "@media(max-width:565px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  textFeild: {
    fontFamily: "Roboto",
    backgroundColor: "#fff",
    boxShadow: "0px 35px 55px -12px rgba(57, 49, 117, 0.1)",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "11px",
    borderRadius: "10px",
    "& input": {
      // color: `${theme.palette.text.primary} !important`,
      color: "#000 !important",
      paddingLeft: "10px",
    },
  },

  icon: {
    cursor: "pointer",
    color: "#fff",
    background: "#3C3C3C",
    marginRight: "10px",
    fontSize: "18px",
    padding: "6px",
    borderRadius: "25px",
    "& :hover": {
      color: "#3DA1E5",
    },
  },
  agree: {
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "14px",
    lineHeight: "31px",
    color: "#FFFFFF",
    "@media (max-width: 1280px)": {
      fontSize: "10px",
    },
  },

  bin: {
    color: theme.palette.text.primary,
  },

  // socialIcons: {
  //   // background: "#3C3C3C",
  //   "& :hover": {
  //     color: "red",
  //   },
  // },
  textBtn: {
    fontFamily: "Roboto",
    color: "#fff",
    width: "160px",
    fontSize: "15px",
    padding: "10px 10px !important",
    // background:"linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
    background: "#0047AB",
    borderRadius: "10px",
    fontWeight: "500",
  },
  listbox: {
    background: "none",
    border: "none",
    margin: "0px",
    "& a": {
      fontFamily: "Ubuntu",
      // color: "#fff",
      display: "block",
      // fontSize: "14px",
      // fontWeight: "400 !important",
      // paddingTop: "10px",

      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "14px",
      // lineHeight: "41px",
      lineHeight: "29px",
      color: "#FFFFFF",
      "@media (max-width: 600px)": {
        fontSize: "12px",
        lineHeight: "12px",
      },
    },
  },

  // fixedborder: {
  //   borderRight: "1px solid #E9C856",
  //   [theme.breakpoints.down("sm")]: {
  //     borderRight: "none",
  //   },
  // },
  listName: {
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "12px",
    lineHeight: "26px",
    padding: "6px !important",
    color: "#FFFFFF",
  },
  socialDiv: {
    width: "100%",
    maxWidth: "225px",
    //margin: "8px auto",
    "@media (max-width: 600px)": {
      paddingTop: "10px",
    },
  },

  socialText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "17px !important",
    color: "#ffffff !important",
    paddingBottom: "0 !important",
  },
  socialImg: {
    display: "flex",
    justifyContent: "left",
    gap: "0.5rem",
  },
  txtField: {
    background: "#3A3A3A",
    borderRadius: "5px",
    height: "47px",
    borderRadius: "100px",
    "& .MuiInputBase-input": {
      padding: "16px 20px",
      color: "#FFFFFF !important",
    },
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  checkboxStyle: {
    "& .MuiFormControlLabel-root": {
      marginRight: "0px !important",
    }
  },
  mOMimgStyle:{
    width: "60px", 
    margin: "0 auto",
    "@media (max-width: 600px)": {
      width: "40px", 
    },
  },
  MOMGtext: {
    fontFamily: "Michroma",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "30px",
    // lineHeight: "28px",
    lineHeight: "34px",
    paddingLeft: "5px",
    color: "#E7DDCE",   
    "@media (max-width: 1280px)": {
      fontSize: "28px",
    },
    "@media (max-width: 1100px)": {
      fontSize: "24px",
    },
    "@media (max-width: 600px)": {
      fontSize: "18px",
    },
  },
  footerSubText: {
    fontFamily: "Ubuntu",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFFFFF",
    fontWeight: "300px",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "22px",
      fontWeight: "200px",
    },
  },
  usefulLinksTextGrid: {
    justifyContent: "center",
    "@media (max-width: 600px)": {
      justifyContent: "left",
    },
    "@media (max-width: 800px)": {
      justifyContent: "left",
    },
  },
  beTheFirstGrid: {
    "@media (max-width: 600px)": {
    padding: "18px",
      },
    "@media (max-width: 800px)": {
    padding: "18px",
      },
  },
  beTheFirstGridTitle: {
    fontFamily: "Michroma",
    fontSize: "20px",
    lineHeight: "48px",
    color: "#E7DDCE",
    "@media (max-width: 600px)": {
      lineHeight: "28px",
      fontSize: "14px",
        },
      "@media (max-width: 800px)": {
        lineHeight: "28px",
      },
  },
  joinNowBtn: {
    fontFamily: "Michroma",
    fontWeight: "400",
    fontSize: "14px",
    color: "#000000",

    background: "#F5C843",
    borderRadius: "100px",
    width: "128px",
    height: "46px",
    color: "#000",
    padding: "0.8rem",
    marginLeft: "-112px",
    marginTop: "8px",
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
    // border: "1px solid ",
  },
  tooltip: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    // border: "1px solid #ee1d23",
  },
  field: {
    backgroundColor: "#3A3A3A !important",
    borderRadius: "50px",
    color: "#fff",
  },
  button: {
    background: "#F5C843",
    borderRadius: "50px",
  },

}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
export default function Liquidity() {
  const handleFormSubmit = async (values) => {};
  const classes = useStyles();
  const history = useHistory();
  const [expiryTime, setExpiryTime] = React.useState(1);
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const themeSeeting = React.useContext(SettingsContext);

  // GetAllCommunityForWebsite

  const [communityLinks, setcommunityLinks] = useState();
  const [scribe, setScribe] = useState();
  const [emailText, setEmailText] = useState("");
  const [error, setError] = useState(null);
  const [termsNpolicy, setTermsNpolicy] = useState (false);

  const listcommunityLinksHandler = async () => {
    // setisLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.GetAllCommunityForWebsite,
      });
      if (res.data.status === 200) {
        setcommunityLinks(res?.data?.data);
        // setisLoading(false);
      } else {
        // setisLoading(false);
      }
    } catch (error) {
      // setisLoading(false);
    }
  };
  useEffect(() => {
    listcommunityLinksHandler();
  }, []);

const HandleCheckBox = () => {
  toast.warn("Please accept terms and conditions to join us")
}

  const ScribeApiHander = async (values) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.subscribeToNewsLetter,
        headers: {
          token: window.localStorage.getItem("token"),
        },
        data: {
          email: emailText,
        },
      });
      if (res.data.responseCode === 200) {
        toast.success(res.data.responseMessage);
        setScribe(res.data.data);
        setIsLoading(false);
      }
      // else if (res.data.responseCode === 403) {
      //   toast.success(res.data.responseMessage);
      //   // setScribe(res.data.data);
      //   setIsLoading(false);
      // }
      setIsLoading(false);
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.responseMessage);
      setIsLoading(false);
    }
  };

  function isValidEmail(emailText) {
    return /\S+@\S+\.\S+/.test(emailText);
  }

  const HandleEmailChange = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }
    setEmailText(e.target.value);
  };




  return (
    <>
      <Box className={classes.footerSection}>
        <Box>
          <Box className="subpart">
            {/* <Container> */}
              <Grid container spacing={5}>
                <Grid item xs={12} md={4} lg={4}
                >
                  <Box>
                    {themeSeeting.settings.theme === "DARK" ? (
                      <>
                        <img
                          src=""
                          alt=""
                          // width="100%"
                          style={{ width: "60px", margin: "0 auto" }}
                        />
                      </>
                    ) : (
                      <>
                        <Grid container>
                          <Grid item xs={3}>
                            <img
                              src="/images/MOM.png"
                              className={classes.mOMimgStyle}
                              style={{ cursor: "pointer",width:"100%",maxWidth:"161px" }}
                              alt=""
                              // width="100%"
                            />
                          </Grid>
                          {/* &nbsp; */}
                          {/* <Grid item xs={7}>
                            <Typography
                            className={classes.MOMGtext}
                            >
                              NexDefi
                            </Typography>
                          </Grid> */}
                        </Grid>
                      </>
                    )}
                    <Typography
                    className={classes.footerSubText}
                    >
                      NexDefi is pure play AI powered web3.0 and metaverse platform for music video/Live online concerts Industry, which is integrating creators (Producer, Artists, Singer, Performers and other stakeholders) as IPR/Copyrights for particular music video/Live concerts with Consumers and Investment community
                    </Typography>
                  </Box>{" "}
                  <Box className={classes.socialDiv}>
                    <div className={classes.socialImg}>
                      <a href="https://www.facebook.com" target="_blank">
                        <img src="images/fb.png" alt="facebook" />
                      </a>
                      <a href="https://www.instagram.com" target="_blank">
                        <img src="images/insta.png" alt="instagram"></img>{" "}
                      </a>
                      <a href="https://www.whatsapp.com" target="_blank">
                        {" "}
                        <img src="images/wp.png" alt="whatsapp"></img>
                      </a>
                      <a href="https://www.youtube.com" target="_blank">
                        {" "}
                        <img src="images/yt.png" alt="youtube"></img>
                      </a>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Grid container className={classes.usefulLinksTextGrid}>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.fixedborder}
                    >
                      <Typography
                        // variant="h3"
                        style={{
                          marginLeft: "7px",
                          fontFamily: "Michroma",
                          fontSize: "20px",
                          lineHeight: "48px",
                          fontWeight: "400",
                          color: "#E7DDCE",
                        }}
                      >
                        Useful Link
                      </Typography>
                      <List className={classes.listbox}>
                        {/* <RouterLink
                          className="hovertext"
                          // to="/About-Us"
                          style={{ textDecoration: "none", color: "#7B838F" }}
                        > */}
                        <ListItem>
                          {" "}
                          <a
                            className={classes.listName}
                            href="https://premusic-nftmarketplace.mobiloitte.org/about-mbit"
                            target="_blank"
                          >
                            About NexDefi
                          </a>
                        </ListItem>
                        {/* </RouterLink> */}

                        <ListItem>
                          {" "}
                          <a
                            className={classes.listName}
                            href="https://premusic-nftmarketplace.mobiloitte.org/"
                            target="_blank"
                          >
                            Marketplace
                          </a>
                        </ListItem>
                        <ListItem >
                          {" "}
                          <a
                            className={classes.listName}
                            href="https://premusic-nftmarketplace.mobiloitte.org/ai-platform"
                            target="_blank"
                          >
                             AI platform
                          </a>
                        </ListItem>
                        <Tooltip title="Coming Soon..">
                         <ListItem >
                          {" "}
                          <a
                            className={classes.listName}
                            // href="https://premusic-nftmarketplace.mobiloitte.org/ai-platform"
                            // target="_blank"
                          >
                          OTT platform
                          </a>
                        </ListItem> 
                        </Tooltip>
                        <ListItem>
                          {" "}
                          <a
                            className={classes.listName}
                            href="https://premusic-nftmarketplace.mobiloitte.org/live-concerts"
                            target="_blank"
                          >
                            Live Concerts
                          </a>
                        </ListItem>
                      </List>{" "}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={4} className={classes.beTheFirstGrid}>
                  <Typography className={classes.beTheFirstGridTitle}>
                    {" "}
                    Be the first to know about NexDefi

                  </Typography>

                  <Box
                    style={{
                      // marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    <TextField
                      // value={values.email}
                      name="email"
                      // placeholder={propsData?.email}
                      placeholder="Enter your email here..."
                      // onChange={handleChange}
                      className={classes.txtField}
                      onChange={(e) => {
                        HandleEmailChange(e);
                      }}
                      value={emailText}
                    ></TextField>
{console.log(termsNpolicy,"termsNpolicy")}
{termsNpolicy ? 
<Button className={classes.joinNowBtn}
                      onClick={() => ScribeApiHander()
                        // handleJoinBtn()
                      }
                    >
                      Join Now {" "} {isLoading ? 
                      <CircularProgress
                      className={classes.bottom}
                      size={20}
                      thickness={4}
                      /> : ""}
                    </Button> : 
                    (
                      <Button className={classes.joinNowBtn}
                      onClick={() => HandleCheckBox()()
                      }
                    >
                      Join Now
                    </Button>
                    )}
                    
                
                  </Box>
                  <Box style={{ height: "20px" }}>
                    {error && (
                      <Typography
                        style={{
                          color: "red",
                          paddingTop: "4px",
                          paddingLeft: "20px",
                        }}
                      >
                        {error}
                      </Typography>
                    )}
                    {console.log(error, "Emailerror")}
                  </Box>

                  <Box style={{ marginLeft: "12px" }}>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <Grid xs={1} className={classes.checkboxStyle}>
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                name="checkboxValue"
                              onClick={() => setTermsNpolicy(!termsNpolicy)}
                                style={{
                                  borderRadius: "2px",
                                }}
                              />
                            }
                            onChange={(e) => e.target.value}
                            // onChange={() => setExpiryTime(7)}
                            //label="Remember me"
                            labelPlacement="end"
                            style={{
                              fontFamily: "Roboto",
                              fontStyle: " normal",
                              fontWeight: " 400",
                              fontSize: "16px",
                              lineHeight: "22px",
                            }}
                          />
                        </Grid>
                        <Grid xs={11}>
                          <Typography
                            //color="primary.main"
                            variant="body1"
                            className={classes.agree}
                          >
                            {/* <Link
                              component={RouterComponent}
                              to="/"
                              className={classes.agree}
                            > */}
                            I agree to the &nbsp;
                            <a
                              href="https://premusic-nftmarketplace.mobiloitte.org/terms-conditions"
                              target="_blank"
                            >
                              <span style={{ color: "#F5C843" }}>
                                terms and conditions
                              </span>
                            </a>
                            &nbsp; and &nbsp;
                            <a
                              href="https://premusic-nftmarketplace.mobiloitte.org/privacy-policy"
                              target="_blank"
                            >
                              <span style={{ color: "#F5C843" }}>
                                privacy policy.
                              </span>
                            </a>
                            {/* </Link> */}
                          </Typography>
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            {/* </Container> */}
            <Box style={{ padding: "12px" }}>
              <Box style={{ borderTop: "1px solid #ffffff" }}></Box>
            </Box>
            <Grid container xs={12} md={12}>
              <Grid item xs={6} md={2}
              // md={2} lg={2}
              >
                <Typography
                  style={{
                    textAlign: "end",
                    borderRight: "1px solid rgba(255, 255, 255, 0.1)",
                    color: "rgba(255, 255, 255, 0.6)",
                    fontSize: "14px",
                    paddingRight: "10px",
                    marginRight: "10px",
                  }}
                >
                  <a
                              href="https://premusic-nftmarketplace.mobiloitte.org/privacy-policy"
                              target="_blank"
                            >
                              <span>
                                Privacy Policy.
                              </span>
                            </a>
                </Typography>
              </Grid>
              <Grid item xs={6} md={2} 
              // md={2} lg={2}
              >
                <Typography
                  style={{
                    color: "rgba(255, 255, 255, 0.6)",
                    fontSize: "14px",
                  }}
                >
                  <a
                              href="https://premusic-nftmarketplace.mobiloitte.org/terms-conditions"
                              target="_blank"
                            >
                              <span>
                              Terms & Conditions
                              </span>
                            </a>
                 
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} 
              // md={4} lg={8}
              >
                <Box className={classes.baseSection} textAlign="center">
                  <Typography variant="body1">
                    Copyright &copy; Created with love by{" "}
                    <span style={{ color: "#F5C843" }}> NexDefi</span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
