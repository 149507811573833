import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  // {
  //   exact: true,
  //   path: "/",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/LogIn")),
  // },

  {
    exact: true,
    path: "/",
    // guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/node",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/nodes/index.js")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/dashboard")),
  },
  {
    exact: true,
    path: "/node-activation",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/nodeActivation")),
  },
  {
    exact: true,
    path: "/api-key",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/apiKey")), 
  },
  {
    exact: true,
    path: "/profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/profile/profile.js")),
  },
  {
    exact: true,
    path: "/edit-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/profile/editProfile.js")),
  },
  {
    exact: true,
    path: "/buy-node",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/BuyNode/index")),
  },
  {
    exact: true,
    path: "/order-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OrderHistory/index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
