import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Grid,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { useHistory, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Web3 from "web3";
import MetaMaskOnboarding from "@metamask/onboarding";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { AuthContext } from "src/context/Auth";
const Bitcoin = require("bitcoin-address-generator");

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "100px",
    paddingRight: "100px",
    paddingTop: "27px",
    background: "black",
    height: "100px",
    borderRadius: "0 !important",

    "@media(max-width: 1130px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "27px",
      background: "black",
      height: "100px",
    },
  },
  toolbar: {
    display: "block",
  },
  desktopHeader: {
    "@media(max-width: 1130px)": {
      display: "none",
    },
  },
  mobileHeader: {
    "@media(min-width: 1130px)": {
      display: "none",
    },
  },
  searchButton: {
    background: "transparent",
    borderRadius: "53px",
    border: "2.5px solid #F5C843",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "14px",
    cursor: "pointer",
    width: "100%",
    // maxWidth:"147px",
    height: "42px",

    color: "#FFFFFF",
    border: "2px solid #F5C843",
    boxShadow:
      "#f5c84338 1px 1px 3px 3px, #f5c84340 0px 0px 3px 3px, #f5c84338 -1px -1px 3px 3px",
    fontWeight: "400",
  },
  headerMenu: {
    display: "flex",
    justifyContent: "end",
    gap: "30px",
  },
  menu: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FFFFFF",
  },
  listItemStyle: {
    textAlign: "center",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  customDrawer: {
    "& .MuiDrawer-paper": {
      background: "#151515",
    },
    // "& .MuiList-padding": {
    // paddingTop: "15px",
    // paddingLeft: "15px",
    // },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [{ metaMaskPresent, metaMaskConnected }, setMetaMaskObject] = useState({
    metaMaskPresent: false,
    metaMaskConnected: false,
  });
  const [publicKey, setPublicKey] = useState("metamask not connected");
  const web3 = new Web3(Web3.givenProvider);
  const connectWalletApi = async (address) => {
    try {
      const res = await Axios.post(ApiConfig.connectWallet, {
        walletAddress: address,
      });
      if (res.data.responseCode == 200) {
        // toast.success(res.data.responseMessage);
        toast.success("Wallet connected.");
        localStorage.setItem("userId", res.data.result._id);
        localStorage.setItem("address", address);
        auth.userLogIn(address, true);
        history.push("/dashboard");
      } else {
        toast.error("Something went wrong.");
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };
  const connectMetaMask = async () => {
    let accounts;
    try {
      await web3?.givenProvider?.request({ method: "eth_requestAccounts" });
      setMetaMaskObject({ metaMaskConnected: true, metaMaskPresent });

      accounts = await web3.eth.getAccounts();

      setPublicKey(() => accounts[0]);

      connectWalletApi(accounts[0]);
    } catch (error) {
      console.error("metmask error", error);
      toast.success(error);
    }
  };

  useEffect(() => {
    const isMetaMaskPresent = () => {
      return web3?.givenProvider?.isMetaMask ? true : false;
    };
    setMetaMaskObject(() =>
      isMetaMaskPresent()
        ? { metaMaskPresent: true, metaMaskConnected }
        : { metaMaskPresent: false, metaMaskConnected }
    );
  }, [web3?.givenProvider?.isMetaMask, metaMaskConnected]);
  const [add, setAdd] = useState("");
  useEffect(() => {
    setAdd(localStorage.getItem("address"));
  }, [localStorage.getItem("address")]);

  // useEffect (()=> {
  //    if (!auth.userLoggedIn) {
  //     window.location.reload()
  //    }
  // },[])

  return (
    <>
      <AppBar
        className={clsx(classes.root, className)}
        color="default"
        {...rest}
      >
        {" "}
        <Toolbar className={classes.toolbar}>
          <div className={classes.desktopHeader}>
            <TopBarData
              metaMaskPresent={metaMaskPresent}
              add={add}
              connectMetaMask={connectMetaMask}
              history={history}
            />
          </div>
          <div className={classes.mobileHeader}>
            {" "}
            <MobileTopBar
              metaMaskPresent={metaMaskPresent}
              add={add}
              connectMetaMask={connectMetaMask}
              history={history}
            />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;

export function TopBarData(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={1} align="start">
          <Box>
            <img
              onClick={() => props.history.push("/")}
              src="/images/MOM.png"
              alt="Logo"
              style={{ cursor: "pointer", width: "100%", maxWidth: "161px" }}
            />{" "}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={props.add ? 7 : 9}
          style={{ paddingTop: "22px" }}
        >
          <Box className={classes.headerMenu}>
            {/* <Box className={classes.menu} style={{color: "#F5C843"}}>Home</Box> */}
            <Box className={classes.menu} style={{ color: "#F5C843" }}>
              <a className={classes.menu} style={{ color: "#F5C843" }}>
                Home
              </a>
            </Box>
            <Box>
              <a
                className={classes.menu}
                href="https://momg.live/"
                target="_blank"
              >
                Marketplace
              </a>
            </Box>
            <Box>
              <Tooltip title="Coming Soon">
                <a
                  className={classes.menu}
                  href="/"
                  // target="_blank"
                >
                  About NexDefi
                </a>
              </Tooltip>
            </Box>
            {/* <Box className={classes.menu} style={{color: "#F5C843"}}>Nodes</Box> */}
            <Box>
              <a
                className={classes.menu}
                href="https://momg.live/live-concerts"
                target="_blank"
              >
                Live Concerts
              </a>
            </Box>
          </Box>
        </Grid>
        {props.add && (
          <Grid item xs={12} md={2}>
            <button
              onClick={() => {
                props.history.push("/dashboard");
              }}
              className={classes.searchButton}
            >
              Dashboard
            </button>
          </Grid>
        )}

        <Grid item xs={12} md={2} style={{ display: "flex", gap: "1rem" }}>
          {!props.metaMaskPresent && (
            <button
              onClick={() => {
                const onboarding = new MetaMaskOnboarding();
                onboarding.startOnboarding();
              }}
              className={classes.searchButton}
              // style={{ width: "500px"}}
            >
              Install Metamask
            </button>
          )}
          <button
            onClick={() => {
              props.connectMetaMask();

              // getAddress();
            }}
            className={classes.searchButton}
          >
            {props.add ? props.add.slice(0, 10) : "Connect wallet"}
          </button>
        </Grid>
      </Grid>
    </>
  );
}
export function MobileTopBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={classes.listItemStyle}>
        <Box mt={2} mb={2}>
          <img
            onClick={() => props.history.push("/")}
            src="/images/MOM.png"
            alt="Logo"
            style={{ cursor: "pointer", width: "100%", maxWidth: "161px" }}
          />{" "}
        </Box>

        <ListItem button className={classes.listItemStyle}>
          <ListItemText className={classes.menu}>
            <Box className={classes.menu} style={{ color: "#F5C843" }}>
              Home
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem button className={classes.listItemStyle}>
          <ListItemText>
            <a
              className={classes.menu}
              href="https://premusic-nftmarketplace.mobiloitte.org/"
              target="_blank"
            >
              Marketplace
            </a>
          </ListItemText>
        </ListItem>
        <ListItem button className={classes.listItemStyle}>
          <ListItemText>
            <a
              className={classes.menu}
              href="https://premusic-nftmarketplace.mobiloitte.org/about-mbit"
              target="_blank"
            >
              About
            </a>
          </ListItemText>
        </ListItem>
        <ListItem button className={classes.listItemStyle}>
          <ListItemText>
            <a
              className={classes.menu}
              href="https://premusic-nftmarketplace.mobiloitte.org/live-concerts"
              target="_blank"
            >
              Live Concerts
            </a>
          </ListItemText>
        </ListItem>
      </List>
      <Divider style={{ background: "white" }} />
      {props.add && (
        <Box
          style={{
            paddingTop: "20px",
            paddingRight: "15px",
            paddingLeft: "15px",
          }}
        >
          {" "}
          <button
            onClick={() => {
              props.history.push("/dashboard");
            }}
            className={classes.searchButton}
          >
            Dashboard
          </button>
        </Box>
      )}
      <Box
        style={{
          paddingTop: "20px",
          paddingRight: "15px",
          paddingLeft: "15px",
        }}
      >
        {!props.metaMaskPresent && (
          <button
            onClick={() => {
              const onboarding = new MetaMaskOnboarding();
              onboarding.startOnboarding();
            }}
            className={classes.searchButton}
          >
            install metamask
          </button>
        )}
        <button
          onClick={() => {
            props.connectMetaMask();

            // getAddress();
          }}
          className={classes.searchButton}
        >
          {props.add ? props.add.slice(0, 10) : "Connect wallet"}
        </button>
      </Box>
    </div>
  );
  return (
    <>
      <Grid container spacing={3} className={classes.contentContainer}>
        <Grid container item xs={6} justifyContent="start">
          {" "}
          <Box>
            <img
              onClick={() => props.history.push("/")}
              src="/images/MOM.png"
              // height={60}

              alt="Logo"
              style={{ cursor: "pointer", width: "100%", maxWidth: "161px" }}
            />
          </Box>
        </Grid>{" "}
        <Grid
          container
          item
          xs={6}
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Box>
            <MenuIcon
              onClick={toggleDrawer("right", true)}
              style={{ color: "#BE8E00", fontSize: "47px" }}
            />
          </Box>
        </Grid>
      </Grid>

      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
        className={classes.customDrawer}
      >
        {list("right")}
      </SwipeableDrawer>
    </>
  );
}
